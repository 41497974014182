import { Dropdown, DropdownMenuItemType, IDropdownOption } from "@fluentui/react";
import { TFunction } from "i18next";
import moment from "moment";
import numbro from "numbro";

import { Cardinality } from "../../../../types/common";
import { EntityWithPermissions } from "../../../../types/entity";
import { Organisation } from "../../../../types/organisation";
import { Report, ReportType } from "../../../../types/reports";
import { formatCentToDecimal } from "../../../../util/numbers";
import DisableableTooltip from "../../../atoms/DisableableTooltip";
import { Forecast, Scenario } from "../../../hooks/useForecasts/types";
import {
  Aggregation,
  CashBalanceObject,
  ParentAggregation,
  PeriodAggregation,
  ReportResponse,
  ReportRowResponse,
} from "../../../hooks/useReports/types";

import { TableData } from "./ReportContext";
import { ReportRow } from "./types";

export function getEndpoint(
  report: Report,
  reportType: ReportType,
  consolidation?: boolean,
  organisation?: Organisation,
  entity?: EntityWithPermissions,
): string | undefined {
  const defaultEndpoint = `${report}/${reportType}`;
  if (consolidation === true && organisation !== undefined)
    return `/organisations/${organisation.id}/reports${
      organisation.permissions.includes("organisation.general_ledger:read") ? "-with-mappings" : ""
    }/${defaultEndpoint}`;
  else if (entity !== undefined)
    return `/entities/${entity.id}/reports${
      entity.permissions.includes("entity.general_ledger:read") ? "-with-mappings" : ""
    }/${defaultEndpoint}`;
}

export function buildTreeTable<T extends ReportRow>(treeData: T[]): T[] {
  const topLevel: T[] = [];
  const parentNotSeen: { [key: string]: T[] } = {};
  const allLines: { [key: string]: T } = {};
  for (const line of treeData) {
    line.subRows = [];
    if (line.top || !line.parent_id) {
      topLevel.push(line);
    } else if (line.parent_id in allLines) {
      allLines[line.parent_id].subRows?.push(line);
    } else if (line.parent_id in parentNotSeen) {
      parentNotSeen[line.parent_id].push(line);
    } else {
      parentNotSeen[line.parent_id] = [line];
    }

    if (line.id in parentNotSeen) {
      line.subRows.push(...parentNotSeen[line.id]);
      delete parentNotSeen[line.id];
    }

    allLines[line.id] = line;
  }
  return topLevel;
}

function checkPLAggregationOption(reportType: ReportType, reportingAggregation: Aggregation): Aggregation {
  if (
    reportType === "actuals" &&
    ((reportingAggregation.type === "period" && reportingAggregation.value !== "year") ||
      reportingAggregation.type === "analyticalAxis")
  )
    return reportingAggregation;
  else if (reportType === "forecast" && reportingAggregation.type === "period" && reportingAggregation.value !== "year")
    return reportingAggregation;
  else if (reportType === "delta" && reportingAggregation.type === "period") return reportingAggregation;
  return { type: "period", value: "month" };
}

function checkBSAggregationOption(reportType: ReportType, reportingAggregation: Aggregation): Aggregation {
  if (
    (reportType === "actuals" || reportType === "forecast") &&
    reportingAggregation.type === "period" &&
    reportingAggregation.value !== "year"
  )
    return reportingAggregation;
  else if (reportType === "delta" && reportingAggregation.type === "period") return reportingAggregation;
  return { type: "period", value: "month" };
}

function checkCFAggregationOption(reportType: ReportType, reportingAggregation: Aggregation): Aggregation {
  if (reportType === "delta" && reportingAggregation.type === "period") return reportingAggregation;
  else if (reportType !== "delta" && reportingAggregation.type === "period" && reportingAggregation.value !== "year")
    return reportingAggregation;
  return { type: "period", value: "month" };
}

function checkRelationsAggregationOption(reportingAggregation: Aggregation): PeriodAggregation {
  if (reportingAggregation.type !== "period" || reportingAggregation.value === "year")
    return { type: "period", value: "month" };
  return reportingAggregation;
}

function checkAggregationOption(
  report: Report,
  reportingAggregation: Aggregation,
  reportType: ReportType,
): Aggregation {
  if (report === "PL") return checkPLAggregationOption(reportType, reportingAggregation);
  else if (report === "BS") return checkBSAggregationOption(reportType, reportingAggregation);
  else return checkCFAggregationOption(reportType, reportingAggregation);
}

function checkConsolidationAggregationOption(
  report: Report,
  reportingAggregation: Aggregation,
  reportType: ReportType,
): Aggregation {
  if (report === "relations") throw Error("Not possible to have relations report for consolidations");
  if (
    (reportType === "actuals" || reportType === "forecast") &&
    ((reportingAggregation.type === "period" && reportingAggregation.value !== "year") ||
      reportingAggregation.type === "entity")
  )
    return reportingAggregation;
  else if (reportType === "delta" && reportingAggregation.type === "period") return reportingAggregation;
  return { type: "period", value: "month" };
}

export function getParentAggregation(aggregation: PeriodAggregation): ParentAggregation | undefined {
  if (aggregation.value === "month") return "quarter";
  else if (aggregation.value === "quarter") return "year";
  return undefined;
}

export function getAggregation(
  reportingAggregation: Aggregation,
  report?: Report,
  reportType?: ReportType,
  consolidation?: boolean,
): Aggregation {
  if (report === "relations") return checkRelationsAggregationOption(reportingAggregation);

  if (!report || !reportType) return { type: "period", value: "month" };
  if (consolidation) return checkConsolidationAggregationOption(report, reportingAggregation, reportType);
  else return checkAggregationOption(report, reportingAggregation, reportType);
}

export function parseReportData(
  data: ReportResponse,
  aggregation: Aggregation,
  lda?: string,
): Omit<TableData, "aggregation"> {
  const allParents = data.data.reduce<string[]>((res, r) => {
    if (r.parent_id !== null) res.push(r.parent_id);
    return res;
  }, []);

  // Check if the row is a parent, and thus has children
  const parsedData = data.data.map(r => ({
    ...r,
    subRows: [],
    hasChildren: allParents.includes(r.id),
    columns:
      typeof Object.values(r.columns)[0] === "string" || Object.values(r.columns)[0] === null
        ? Object.keys(r.columns).reduce((res: Record<string, number>, k: string) => {
            res[k] = formatCentToDecimal(r.columns[k] as string);
            return res;
          }, {})
        : Object.keys(r.columns).reduce((res: Record<string, Record<string, number>>, k: string) => {
            res[k] = Object.keys(r.columns[k]).reduce((actBudDel: Record<string, number>, currK: string) => {
              actBudDel[currK] = formatCentToDecimal((r.columns[k] as Record<string, string>)[currK]);
              return actBudDel;
            }, {});
            return res;
          }, {}),
  }));
  return {
    data: buildTreeTable<ReportRow>(parsedData),
    headerIdToName:
      aggregation.type === "analyticalAxis" || aggregation.type === "entity"
        ? data.meta.columns.reduce<Record<string, string>>((res, curr, i) => {
            if (data.meta.columnIds) res[data.meta.columnIds[i]] = curr;
            return res;
          }, {})
        : {},
    metaData: {
      ...data.meta,
      columnType: data.meta.columns.map((col, i) => {
        // Entity view - column type
        if (aggregation.type === "entity" && data.meta.columnIds && data.meta.columnIds[i] === "entity_total")
          return "forecast";
        else if (aggregation.type === "entity") return "actuals";

        // Analytical Axis view - column type
        if (aggregation.type === "analyticalAxis") return "actuals";

        // Period view - column type
        if (aggregation.value === "year") return col === "YTD" ? "actuals" : "forecast";
        else if (!lda) return "actuals";
        else return moment(col, data.meta.columnFormat).isSameOrBefore(lda) ? "actuals" : "forecast";
      }),
    },
  };
}

export function getFilters(
  aggregationOptions: IDropdownOption[],
  aggregationValue: string,
  setReportingAggregation: (newReportAggregation: Aggregation) => void,
  cardinality: Cardinality,
  setCardinality: (cardinality: Cardinality) => void,
  t: TFunction,
  missingRevenueCm: boolean,
  report?: Report,
) {
  const cardinalityOptions: IDropdownOption[] = [
    ...new Array(3).fill(0).map((_, i) => {
      const o = Math.pow(1000, i);
      return {
        key: o,
        text: `(x ${numbro(o).format({
          thousandSeparated: true,
          mantissa: 0,
        })})`,
        data: {
          type: "value",
          value: o,
        },
      };
    }),
    ...(report === "PL" && aggregationValue !== "entity"
      ? [
          {
            key: "divider-percentage-of-revenue",
            text: "-",
            itemType: DropdownMenuItemType.Divider,
          },
          {
            key: "percentage-of-revenue",
            text: t("cardinality.percentageOfRevenue", { ns: "reports" }),
            data: { type: "percent", value: "revenue" },
          },
        ]
      : []),
  ];
  return [
    <Dropdown
      selectedKey={aggregationValue}
      styles={{ title: { borderWidth: 0 } }}
      dropdownWidth={"auto"}
      options={aggregationOptions}
      onChange={async (_, item?: IDropdownOption<Aggregation>) => {
        if (item === undefined || item.data === undefined) return;
        const newReportingAggregation: Aggregation = {
          type: item.data.type,
          value: item.data.value,
        } as Aggregation;
        if (newReportingAggregation.type === "entity" && cardinality.type === "percent")
          setCardinality({ type: "value", value: 1 });
        setReportingAggregation(newReportingAggregation);
      }}
    />,
    <Dropdown
      dropdownWidth="auto"
      defaultSelectedKey={cardinality.type === "value" ? cardinality.value : "percentage-of-revenue"}
      disabled={setCardinality === undefined}
      styles={{
        root: {
          display: "inline-block",
          verticalAlign: "middle",
          marginBottom: 1,
        },
        dropdown: { "&.is-disabled > .ms-Dropdown-title": { backgroundColor: "transparent" } },
        title: { border: "none", paddingLeft: 0 },
      }}
      options={cardinalityOptions}
      onChange={(_, option) => {
        if (option === undefined) return;
        setCardinality(option.data);
      }}
      onRenderItem={(props, defaultRender) => {
        if (props === undefined || defaultRender === undefined) return null;
        if (props.key !== "percentage-of-revenue") return defaultRender(props);
        props.disabled = missingRevenueCm;
        return (
          <DisableableTooltip
            disabled={!missingRevenueCm}
            content={t("cardinality.percentageOfRevenueDisabled", { ns: "reports" })}
          >
            {defaultRender(props)}
          </DisableableTooltip>
        );
      }}
    />,
  ];
}

function flattenSubRows(subRow: ReportRow): ReportRow[] {
  return [subRow, ...(subRow.subRows?.flatMap(flattenSubRows) ?? [])];
}

export function flattenTableData(tableData: ReportRow[]): ReportRow[] {
  return tableData.flatMap(d => [d, ...(d.subRows?.flatMap(flattenSubRows) ?? [])]);
}

export function getDefaultScenarioBudgetVersionIdsByBudgetVersionIds(
  forecasts: Forecast[],
  budgetVersionIds?: string[],
): string[] {
  if (budgetVersionIds === undefined || budgetVersionIds.length === 0) return [];

  // We look for the scenario that matches most budget versions
  const scenario = forecasts.reduce<[Scenario | undefined, number]>(
    (res, fc) => {
      fc.scenarios.forEach(sc => {
        const numbMatchingBudVers = sc.budgetVersions?.filter(bv => budgetVersionIds.includes(bv.id)).length ?? 0;
        if (numbMatchingBudVers > res[1]) res = [sc, numbMatchingBudVers];
      });
      return res;
    },
    [undefined, 0],
  )[0];
  if (scenario === undefined) return [];

  // For that scenario we see which scenario is the default one in the same forecast. We take the budget versions of that scenario.
  return (
    forecasts
      .find(f => f.scenarios.map(sc => sc.id).includes(scenario.id))
      ?.scenarios.find(sc => sc.default)
      ?.budgetVersions?.map(bv => bv.id) ?? []
  );
}

export function getDefaultScenarioBudgetVersionIdsByBudgetVersionIdsConsolidation(
  forecasts: Forecast[],
  budgetVersionIds?: string[],
): string[] {
  if (budgetVersionIds === undefined || budgetVersionIds.length === 0) return [];

  const forecastsByEntityId = forecasts.reduce<Record<string, Forecast[]>>((res, fc) => {
    res[fc.entityId] = [...(res[fc.entityId] ?? []), fc];
    return res;
  }, {});
  return Object.values(forecastsByEntityId).flatMap(fcs =>
    getDefaultScenarioBudgetVersionIdsByBudgetVersionIds(fcs, budgetVersionIds),
  );
}

export function getFormattedCashBalanceLine(
  data: ReportRowResponse[],
  cashAndBankCmRepLineId: string,
): [CashBalanceObject[], CashBalanceObject[] | undefined] {
  const cashAndBankLine = data.find(row => row.id === cashAndBankCmRepLineId);
  if (cashAndBankLine === undefined) return [[], undefined];

  return typeof Object.values(cashAndBankLine.columns)[0] === "string" ||
    Object.values(cashAndBankLine.columns)[0] === null
    ? [
        Object.entries(cashAndBankLine.columns).reduce<CashBalanceObject[]>((res, [col, value]) => {
          const currDate = moment(col, ["YYYY-MM", "YYYY-[Q]Q"]);
          res.push({ date: currDate.startOf("month").format("YYYY-MM-DD"), amount: formatCentToDecimal(value ?? 0) });
          return res;
        }, []),
        undefined,
      ]
    : Object.entries(cashAndBankLine.columns).reduce<[CashBalanceObject[], CashBalanceObject[]]>(
        (res, [col, value]) => {
          const currDate = moment(col, ["YYYY-MM", "YYYY-[Q]Q"]);
          res[0].push({
            date: currDate.startOf("month").format("YYYY-MM-DD"),
            amount: formatCentToDecimal(value.actual ?? 0),
          });
          res[1].push({
            date: currDate.startOf("month").format("YYYY-MM-DD"),
            amount: formatCentToDecimal(value.budget ?? 0),
          });
          return res;
        },
        [[], []],
      );
}
